.sponsors-wrapper {
    background: #ffffff;
    padding: 60px 0;
}

.sponsors-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.sponsors-header {
    text-align: center;
    margin-bottom: 40px;
}

.sponsors-label {
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    color: #007bff;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    margin-bottom: 10px;
}

.sponsors-header h2 {
    font-size: 32px;
    font-weight: 600;
    color: #1a1a1a;
    margin: 0 0 15px 0;
}

.sponsors-subtitle {
    font-size: 18px;
    color: #666;
    max-width: 600px;
    margin: 0 auto;
}

.sponsors-logos {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    flex-wrap: wrap;
    margin: 40px 0;
    padding: 20px;
    border-radius: 10px;
}

.sponsor-logo-link {
    background: none;
    border: none;
    box-shadow: none;
    transition: transform 0.3s ease;
}

.sponsor-logo-link:hover {
    transform: translateY(-5px);
}

.sponsor-logo-link img {
    background: transparent;
    width: 100px;
    height: auto;
    object-fit: contain;
}

.sponsors-cta {
    text-align: center;
    margin-top: 40px;
    padding: 30px;
    background: #f8f9fa;
    border-radius: 10px;
}

.sponsors-cta p {
    font-size: 18px;
    color: #333;
    margin-bottom: 20px;
}

.cta-buttons {
    display: flex;
    gap: 15px;
    justify-content: center;
    flex-wrap: wrap;
}

.cta-primary, .cta-secondary {
    padding: 12px 24px;
    border-radius: 6px;
    font-weight: 500;
    transition: all 0.3s ease;
    text-decoration: none;
}

.cta-primary {
    background: #007bff;
    color: white;
}

.cta-primary:hover {
    background: #0056b3;
    transform: translateY(-2px);
}

.cta-secondary {
    background: white;
    color: #007bff;
    border: 1px solid #007bff;
}

.cta-secondary:hover {
    background: #f0f7ff;
    transform: translateY(-2px);
}

@media (max-width: 768px) {
    .sponsors-wrapper {
        padding: 40px 0;
    }

    .sponsors-header h2 {
        font-size: 24px;
    }

    .sponsors-subtitle {
        font-size: 16px;
    }

    .sponsors-logos {
        gap: 20px;
    }
    
    .sponsor-logo-link img {
        width: 80px;
    }

    .sponsors-cta {
        padding: 20px;
    }

    .cta-buttons {
        flex-direction: column;
    }
}
