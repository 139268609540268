/* Header Styles */
.main-bar {
    background: #ffffff;
    padding: 15px 0;
    box-shadow: 0 2px 10px rgba(0,0,0,0.08);
}

.brand-container {
    display: flex;
    align-items: center;
    gap: 15px;
    text-decoration: none;
}

.logo-wrapper {
    display: flex;
    align-items: center;
}

.brand-text {
    display: flex;
    flex-direction: column;
}

.brand-name {
    font-size: 1.4rem;
    font-weight: 700;
    color: #2d3436;
    letter-spacing: -0.5px;
    line-height: 1.2;
}

.brand-tagline {
    font-size: 0.9rem;
    color: #636e72;
    font-weight: 500;
}

.header-nav-buttons {
    display: flex;
    gap: 15px;
    align-items: center;
}

.nav-button {
    padding: 10px 20px;
    border-radius: 8px;
    font-weight: 600;
    text-decoration: none;
}

.nav-button.primary {
    background: #1abc9c;
    color: white;
}

.nav-button.secondary {
    background: #f5f6fa;
    color: #2d3436;
}

.nav-button:hover {
    opacity: 0.9;
}

/* Responsive */
@media only screen and (max-width: 768px) {
    .brand-tagline {
        /* display: none; */
    }
    
    .brand-name {
        font-size: 1.2rem;
        /* display: none; */
    }
    
    .header-nav-buttons {
        gap: 8px;
    }
    
    .nav-button {
        padding: 8px 12px;
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 768px) {
    .desktop-only {
        display: none !important;
    }
}

/* Optimizaciones para mobile */
@media (max-width: 768px) {
    .site-header {
        contain: layout style paint;
        content-visibility: auto;
    }

    .logo {
        will-change: transform;
        transform: translateZ(0);
        backface-visibility: hidden;
    }

    .site-button {
        touch-action: manipulation;
        -webkit-tap-highlight-color: transparent;
        user-select: none;
    }

    /* Prevenir CLS */
    .logo-wrapper {
        min-height: 60px;
        min-width: 60px;
    }
}
