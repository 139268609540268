.select2-container--default .select2-selection--single {
    background-color: white !important;
    border: 1px solid #e0e0e0 !important;
    border-radius: 6px !important;
    height: 45px !important;
    transition: all 0.2s ease !important;
}

.select2-container--default .select2-selection--single:hover {
    border-color: #2196f3 !important;
    box-shadow: 0 2px 4px rgba(33, 150, 243, 0.1) !important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 42px !important;
    padding-left: 15px !important;
    color: #333 !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 43px !important;
}

.select2-dropdown {
    border: 1px solid #2196f3 !important;
    border-radius: 6px !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
}

.select2-search__field {
    padding: 8px 8px 8px 35px !important;
    border: 3px solid #2196f3 !important;
    border-radius: 6px !important;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%23888" class="bi bi-search" viewBox="0 0 16 16"><path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/></svg>') no-repeat 10px center !important;
    background-color: white !important;
}

.select2-search__field:focus {
    border-color: #2196f3 !important;
    outline: none !important;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #2196f3 !important;
    color: white !important;
}

/* Estilo para los encabezados de grupo */
.select2-results__group {
    color: #1976d2;
    font-weight: 700;
    font-size: 0.85em;
    letter-spacing: 0.5px;
    padding: 12px 8px 8px 8px;
    border-bottom: 2px solid #e0e0e0;
    background: linear-gradient(to right, #d2d2d2, #e0e0e0);
    pointer-events: none;
    text-transform: uppercase;
    cursor: not-allowed;
    opacity: 0.8;
    user-select: none;
}

/* Optimizaciones para Select2 en móvil */
.select2-dropdown-throttled {
    animation: none !important;
    transition: none !important;
}

@media (max-width: 768px) {
    .select2-container--open .select2-dropdown {
        will-change: transform;
    }
    
    .select2-results__options {
        contain: content;
    }
}
